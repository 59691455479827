/**
 * This is the main controller for all pages.
 */
import SmoothScroll from 'smooth-scroll'
import Form from "./Form"

export default class Pages {
    constructor() {
        this.config = {
            pulloutContainer: document.querySelector('#pullout-container'),
            pulloutTriggers: document.querySelectorAll('.open-pullout'),
            pulloutClosers: document.querySelectorAll('.close-pullout'),
            body: document.querySelector('body')
        }

        this.form = new Form()

        this._addEventListeners()
    }

    _addEventListeners = () => {
        // Bind the Open Pullout handlers
        Array.from(this.config.pulloutTriggers).forEach(trigger => {
            trigger.addEventListener('click', this.openPullout)
        })
        
        // Bind the Close Pullout handlers
        Array.from(this.config.pulloutClosers).forEach(closer => {
            closer.addEventListener('click', this.closePullout)
        })
    }

    openPullout = e => {
        e.preventDefault()
        this.config.pulloutContainer.classList.add('open')
        this.config.body.classList.add('noscroll')
    }

    closePullout = e => {
        e.preventDefault()
        this.config.pulloutContainer.classList.remove('open')
        this.config.body.classList.remove('noscroll')
    }

    smoothScroll = () => {
        return new SmoothScroll('a[href*="#"]', {
            easing: 'easeInOutQuad',
            speed: 800
        })
    }

    init = () => {
        this.smoothScroll()
    }
}