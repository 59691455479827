/**
 * Main Controller for the contact form.
 * Handles all logic back and forth between user input and application.
 */
import axios from 'axios'

export default class Form {
    constructor() {
        this.config = {
            form: document.querySelector('#contact-form'),
            textInputs: Array.from(document.querySelectorAll('#contact-form .input')),
            nameField: document.querySelector('input[name="full-name"]'),
            emailField: document.querySelector('input[name="email"]'),
            phoneField: document.querySelector('input[name="phone"]'),
            companyField: document.querySelector('input[name="company"]'),
            submitButton: document.querySelector('#contact-form button[type="submit"]')
        }

        this._addEventListeners()
    }

    _addEventListeners = () => {
        this.config.submitButton.addEventListener('click', this.handleFormSubmit)
    }

    _verifyForm = () => {
        console.log('verify');
        return true
    }

    handleFormSubmit = e => {
        // e.preventDefault()

        if (this._verifyForm()) {

        }
    }
    
    init = () => {
    }
}